import __request__ from 'routing/request';
interface Binding {
    readonly type: string;
}
interface ExecutionState {
    readonly parameter_bindings: {
        readonly [_key: string]: Binding;
    };
    readonly global_override: ExecutionStateGlobalOverride;
    readonly additional_parameters: {
        readonly [_key: string]: Object;
    };
    readonly queries: {
        readonly [_key: string]: ExecutionStateGlobalOverride;
    };
}
interface AbsoluteRange {
    readonly from: string;
    readonly to: string;
    readonly type: string;
}
interface ResultFormat {
    readonly timerange: AbsoluteRange;
    readonly filename: string;
    readonly limit: number;
    readonly execution_state: ExecutionState;
    readonly time_zone: string;
    readonly fields_in_order: string[];
}
interface MessagesRequest {
    readonly timerange: TimeRange;
    readonly chunk_size: number;
    readonly streams: string[];
    readonly limit: number;
    readonly fields_in_order: string[];
    readonly time_zone: string;
    readonly query_string: ElasticsearchQueryString;
}
interface ElasticsearchQueryString {
    readonly query_string: string;
}
interface ExecutionStateGlobalOverride {
    readonly timerange: TimeRange;
    readonly keep_search_types: string[];
    readonly offset: number;
    readonly query: BackendQuery;
    readonly limit: number;
    readonly search_types: {
        readonly [_key: string]: SearchTypeExecutionState;
    };
    readonly keep_queries: string[];
}
interface TimeRange {
    readonly type: string;
}
interface SearchTypeExecutionState {
    readonly offset: number;
    readonly limit: number;
    readonly after: unknown[];
}
interface Object {
}
interface BackendQuery {
}
/**
 * Export messages as CSV
 */
export function retrieve(arg0?: MessagesRequest): Promise<unknown> {
    return __request__('POST', '/views/search/messages', arg0, {}, {
        'Accept': ['text/csv']
    });
}
/**
 * Retrieve results for export job
 * @param exportJobId ID of an existing export job
 * @param filename Resulting filename
 */
export function retrieveForExportJob(exportJobId?: string, filename?: string): Promise<unknown> {
    return __request__('GET', `/views/search/messages/job/${exportJobId}/${filename}`, null, {}, {
        'Accept': []
    });
}
/**
 * Export a search result as CSV
 * @param searchId ID of an existing Search
 * @param Optionaloverrides Optional overrides
 */
export function retrieveForSearch(searchId?: string, Optionaloverrides?: ResultFormat): Promise<unknown> {
    return __request__('POST', `/views/search/messages/${searchId}`, Optionaloverrides, {}, {
        'Accept': ['text/csv']
    });
}
/**
 * Export a message table as CSV
 * @param searchId ID of an existing Search
 * @param searchTypeId ID of a Message Table contained in the Search
 * @param Optionaloverrides Optional overrides
 */
export function retrieveForSearchType(searchId?: string, searchTypeId?: string, Optionaloverrides?: ResultFormat): Promise<unknown> {
    return __request__('POST', `/views/search/messages/${searchId}/${searchTypeId}`, Optionaloverrides, {}, {
        'Accept': []
    });
}
