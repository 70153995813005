import __request__ from 'routing/request';
interface Entity {
    readonly v: string;
    readonly id: string;
    readonly type: ModelType;
}
interface EntityDescriptor {
    readonly id: string;
    readonly type: ModelType;
}
interface EntityExcerpt {
    readonly id: string;
    readonly title: string;
    readonly type: ModelType;
}
interface CatalogResolveRequest {
    readonly entities: EntityDescriptor[];
}
interface EntityTitleRequest {
    readonly entities: EntityIdentifier[];
}
interface EntityTitleResponse {
    readonly id: string;
    readonly type: string;
    readonly title: string;
}
interface ModelType {
    readonly name: string;
    readonly version: string;
}
interface CatalogResolveResponse {
    readonly entities: Entity[];
}
interface CatalogIndexResponse {
    readonly entities: EntityExcerpt[];
}
interface EntityIdentifier {
    readonly id: string;
    readonly type: string;
}
interface EntitiesTitleResponse {
    readonly entities: EntityTitleResponse[];
    readonly not_permitted_to_view: string[];
}
/**
 * List available entities in this Graylog cluster
 */
export function showEntityIndex(): Promise<CatalogIndexResponse> {
    return __request__('GET', '/system/catalog', null, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Resolve dependencies of entities and return their configuration
 */
export function resolveEntities(JSONbody: CatalogResolveRequest): Promise<CatalogResolveResponse> {
    return __request__('POST', '/system/catalog', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
/**
 * Get titles of provided entities
 */
export function getTitles(JSONbody: EntityTitleRequest): Promise<EntitiesTitleResponse> {
    return __request__('POST', '/system/catalog/entities/titles', JSONbody, {}, {
        'Accept': ['application/json']
    });
}
